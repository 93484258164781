#photoHeader {
  height: 426px;
  background-color: black;
  background-size: cover !important;
}
#photoHeader > div {
  color: white;
  padding: 64px 40px;
}
#photoHeader > div > h1 {
  font-size: 48px;
}
#mainContent {
  padding-right: 1rem;
  max-width: 60rem;
}

hr {
  width: 100%;
  color: #ebebeb;
}

a {
  color: rgba(2, 122, 151, 1);
  text-decoration: none;
}

#controls > button {
  margin: 0 4px;
  font-size: 16px;
  padding: 8px 8px;
}

#page {
  margin-top: 24px;
  padding: 0px 20px;
}
#mainContent {
  flex: 1;
}

.component {
  display: none;
}

.headerSpan {
  padding-block: 5px;
}
