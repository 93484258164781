

.starBox {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  z-index: 5;
  text-align: center;
  padding: 0px;
}

.rating {
  display: flex;
  gap: 2px;
  align-items: center;
  width: fit-content;
  z-index: 2;
  overflow: hidden;
  background-color: white;
}

.hoverText {
  background-color: white;
  margin-left: 5px;
  width: 150px;
}


