/* Assign to parents for changing direction */
.row { display: flex; flex-direction: row; }
.col { display: flex; flex-direction: column; }
/* Assign to children of row/col to center */
.row > .centered { align-self: center; margin: auto 0px; }
.col > .centered { align-self: center; margin: 0px auto; }

/* global vars */
body {
  --red: #e00707;
  padding: 0px;
  margin: 0px;
}

/* general stuff */
.outlineBox {
  outline: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 16px;
}
h1,h2,h3,h4,h5,h6,p { margin: 0px; }

/* input styling */
button, input, select {
  display: flex;
  align-items: center;

  background-color: transparent;
  border: 0;
  border: 1px solid #c8c9ca;
  border-radius: 4px;
  justify-content: center;
  padding: 8px 16px;
}
button:hover {
  background-color: rgba(0,0,0,0.12);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}
button.red {
  background-color: var(--red);
  border: 0;
  color: white;
  fill: white;
}
button.red:hover {
  background-color: rgb(244,13,21);
  color: rgba(255,255,255,1);
}

#footer {
  margin-top: 60px;
  padding: 60px 0;
  justify-content: center;
  gap: 50px;
  background-color: #f7f7f7;
}

#footer > div {
  margin: 0 12px;
}

#footer a {
  color: rgba(110,112,114,1);

  line-height: 20px;
  font-size: 14px;
  margin-top: 8px;
}

#footer a:hover {
  text-decoration-line: underline;
}

.softBottomBorder {
  border-bottom: 1px solid #ebebeb;
}

[id*="BusinessDetails"] [class*="StarRating_average"] {
  color: white!important;
}

.legalText {
  color: #333;
  font-size: 12px;
  line-height: 1.5em;
  text-align: center;
}

textarea {
  border: 1px solid rgba(200,201,202,1) !important;
  border-radius: 4px;
  cursor: text;
  resize: none;
  font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;
  padding: 24px;
}

/* Styles for conditional rendering of StarRatingPicker */
.createReview {
  position: absolute;
  left: 20px;
  top: 20px;
}

.editReview {
  position: static;
}