#home {
  margin-top: 30px;
}

.category {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  margin: 0px 20px;
  margin-top: 40px;
  width: 20vw;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
}
.category:hover {
  background-color: transparent;
  box-shadow: 0 0 10px 0 #00000026;
}

.heroBox {
  position: relative;
  top: 0;
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(180deg, rgba(46, 46, 46, 0.97),rgba(46, 46, 46, .1) 40%), url('https://source.unsplash.com/1600x900/?store');
  background-size: cover;
  margin-bottom: 50px;
  z-index: -5;
}