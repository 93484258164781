
.nav {
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  z-index: 5;
  padding: 24px 0px;
  margin: 0px;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
  background-color: #fff;
  top: 0px;
}

.stickyBtn {
  color: black;
  font-weight: 600;
  margin-inline: 10px;
}

.logo {
  height: 40px;
  margin-left: 40px;
}


button.headerBtn {
  color: white;
  font-weight: 600;
  margin-inline: 10px;
}

.login {
  border: 2px solid white;
}

#userArea {
  margin-right: 30px;
}