.listing {
  display: flex;
  gap: 10px;
  padding: 24px;
  width: 75%;
  height: 270px;
  box-sizing: border-box;
  color: #6e7072;
  font-size: .9rem;
}

.listing:hover {
  transition: .4sec;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 10px;
}

.img {
  min-width: 220px;
  max-width: 220px;
  min-height: 220px;
  max-height: 220px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 24px;
}
.link {
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.type {
  font-size: .8rem;
  font-weight: 600;
  background-color: #F0F0F0;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
}

.price {
  margin-left: 10px;
}

.commentBox {
  display: flex;
  gap: 10px;
}

.chatIcon {
  position: relative;
  top: 3px;
}

.transactionBox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: .8rem;
}

.transaction {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.svg {
  color: #2eb187;
}


