
.starComponent {
  display: flex;
  align-items: center;
}

.starsBox {
  position: relative;
  display: flex;
  width: fit-content;
  gap: 2px;
  z-index: 0;
  overflow: hidden;
}

.starBox {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  position: relative;
  z-index: 5;
  text-align: center;
}

.data {
  padding-left: 5px;
}

.average {
  color: black;
}
  