.textareaBox {
  position: relative;
}

.submitButton {
  width: 10em;
}

.footer {
  margin-bottom: 20rem;
}

.edit {
  padding: 5px;
  align-content: flex-end;
  background-color: #e00707;
  color: white;
  width: 70px;
}

.delete {
  padding: 5px;
  align-content: flex-end;
  background: #e00707;
  color: white;
  width: 70px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 20px;
    margin-left: auto;
    height: 25px;
}

.textbox {
  width: 80%;
  max-width: 700px;
  height: 7em;
  padding-top: 1.8rem;
}