body {
  margin: 0;
  font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Assign to parents for changing direction */
.row { display: flex; flex-direction: row; }
.col { display: flex; flex-direction: column; }
/* Assign to children of row/col to center */
.row > .centered { align-self: center; margin: auto 0px; }
.col > .centered { align-self: center; margin: 0px auto; }

/* global vars */
body {
  --red: #e00707;
  padding: 0px;
  margin: 0px;
}

/* general stuff */
.outlineBox {
  outline: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 16px;
}
h1,h2,h3,h4,h5,h6,p { margin: 0px; }

/* input styling */
button, input, select {
  display: flex;
  align-items: center;

  background-color: transparent;
  border: 0;
  border: 1px solid #c8c9ca;
  border-radius: 4px;
  justify-content: center;
  padding: 8px 16px;
}
button:hover {
  background-color: rgba(0,0,0,0.12);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}
button.red {
  background-color: var(--red);
  border: 0;
  color: white;
  fill: white;
}
button.red:hover {
  background-color: rgb(244,13,21);
  color: rgba(255,255,255,1);
}

#footer {
  margin-top: 60px;
  padding: 60px 0;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  background-color: #f7f7f7;
}

#footer > div {
  margin: 0 12px;
}

#footer a {
  color: rgba(110,112,114,1);

  line-height: 20px;
  font-size: 14px;
  margin-top: 8px;
}

#footer a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.softBottomBorder {
  border-bottom: 1px solid #ebebeb;
}

[id*="BusinessDetails"] [class*="StarRating_average"] {
  color: white!important;
}

.legalText {
  color: #333;
  font-size: 12px;
  line-height: 1.5em;
  text-align: center;
}

textarea {
  border: 1px solid rgba(200,201,202,1) !important;
  border-radius: 4px;
  cursor: text;
  resize: none;
  font-family: 'Poppins','Helvetica Neue',Helvetica,Arial,sans-serif;
  padding: 24px;
}

/* Styles for conditional rendering of StarRatingPicker */
.createReview {
  position: absolute;
  left: 20px;
  top: 20px;
}

.editReview {
  position: static;
}
#BusinessDetails_photoHeader__3Ga1c {
  height: 426px;
  background-color: black;
  background-size: cover !important;
}
#BusinessDetails_photoHeader__3Ga1c > div {
  color: white;
  padding: 64px 40px;
}
#BusinessDetails_photoHeader__3Ga1c > div > h1 {
  font-size: 48px;
}
#BusinessDetails_mainContent__mTz2_ {
  padding-right: 1rem;
  max-width: 60rem;
}

hr {
  width: 100%;
  color: #ebebeb;
}

a {
  color: rgba(2, 122, 151, 1);
  text-decoration: none;
}

#BusinessDetails_controls__2PAh_ > button {
  margin: 0 4px;
  font-size: 16px;
  padding: 8px 8px;
}

#BusinessDetails_page__10ftF {
  margin-top: 24px;
  padding: 0px 20px;
}
#BusinessDetails_mainContent__mTz2_ {
  flex: 1 1;
}

.BusinessDetails_component__2oxVe {
  display: none;
}

.BusinessDetails_headerSpan__19PNn {
  padding-block: 5px;
}


.StarRating_starComponent__3tnBQ {
  display: flex;
  align-items: center;
}

.StarRating_starsBox__b9tuL {
  position: relative;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 2px;
  gap: 2px;
  z-index: 0;
  overflow: hidden;
}

.StarRating_starBox__at012 {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  position: relative;
  z-index: 5;
  text-align: center;
}

.StarRating_data__2khPk {
  padding-left: 5px;
}

.StarRating_average__2kjLi {
  color: black;
}
  


.StarRatingPicker_starBox__1-KYy {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  z-index: 5;
  text-align: center;
  padding: 0px;
}

.StarRatingPicker_rating__1iTkd {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 2;
  overflow: hidden;
  background-color: white;
}

.StarRatingPicker_hoverText__PxP8J {
  background-color: white;
  margin-left: 5px;
  width: 150px;
}



.Review_textareaBox__j80Jd {
  position: relative;
}

.Review_submitButton__1nc33 {
  width: 10em;
}

.Review_footer__1ocz- {
  margin-bottom: 20rem;
}

.Review_edit__2qtWv {
  padding: 5px;
  align-content: flex-end;
  background-color: #e00707;
  color: white;
  width: 70px;
}

.Review_delete__2Pzg6 {
  padding: 5px;
  align-content: flex-end;
  background: #e00707;
  color: white;
  width: 70px;
}

.Review_buttonContainer__cWVbF {
    display: flex;
    flex-direction: row;
    position: relative;
    grid-gap: 20px;
    gap: 20px;
    margin-left: auto;
    height: 25px;
}

.Review_textbox__2YDKh {
  width: 80%;
  max-width: 700px;
  height: 7em;
  padding-top: 1.8rem;
}

.Header_nav__3s-E5 {
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  z-index: 5;
  padding: 24px 0px;
  margin: 0px;
}

.Header_absolute__nDTYv {
  position: absolute;
}

.Header_sticky__1d6GG {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  top: 0px;
}

.Header_stickyBtn__3xKI6 {
  color: black;
  font-weight: 600;
  margin-inline: 10px;
}

.Header_logo__2xWOa {
  height: 40px;
  margin-left: 40px;
}


button.Header_headerBtn__1D-an {
  color: white;
  font-weight: 600;
  margin-inline: 10px;
}

.Header_login__2MllH {
  border: 2px solid white;
}

#Header_userArea__3ZX8f {
  margin-right: 30px;
}
.SearchBar_searchBar__3hlNe {
  width: 100%;
  max-width: 968px;
  height: 48px;
  margin-right: auto;
  margin-left: 33px;
  border-radius: 5px;
  box-shadow: 0 2px 18px #00000026;
}

.SearchBar_form__3P85N {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}

.SearchBar_inputL__3aHKS, .SearchBar_inputR__1gfE8 {
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  border-radius: 0;
  padding: 0px 13px;
}

.SearchBar_inputL__3aHKS {
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
}

.SearchBar_inputR__1gfE8 {
  border: none;
}

.SearchBar_searchBtn__1oU0O {
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.SearchBar_searchIcon__2ROmI {
  height: 100%;
  background-color: #E00707;
  padding: 0px;
  margin: 0px;
  border: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.SearchBar_borderPillContainer__i_1t3 {
  background-color: white;
  border: none;
}
.SearchBar_borderPill__6mEZI {
  background-color: #ebebeb;
  margin-top: auto;
  margin-bottom: auto;
  width: 2px;
  height: 60%;
}
.CreateReview_form__1uaSs {
  margin-left: 20rem;
  margin-top: 2rem;
}

.CreateReview_textareaBox__3fJLt {
  position: relative;
}

.CreateReview_textbox__1zTm1 {
  width: 50em;
  height: 25em;
  padding-top: 5rem;
  background: white;
}

.CreateReview_submitButton__RHv3X {
  width: 10em;
}

.CreateReview_footer__3FQin {
  margin-bottom: 20rem;
}
.BusinessResult_listing__2tsBE {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 24px;
  width: 75%;
  height: 270px;
  box-sizing: border-box;
  color: #6e7072;
  font-size: .9rem;
}

.BusinessResult_listing__2tsBE:hover {
  transition: .4sec;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 10px;
}

.BusinessResult_img__3Cw4x {
  min-width: 220px;
  max-width: 220px;
  min-height: 220px;
  max-height: 220px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 24px;
}
.BusinessResult_link__tK-OT {
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
}

.BusinessResult_link__tK-OT:hover {
  text-decoration: underline;
}

.BusinessResult_content__3c_4u {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.BusinessResult_type__3hYI7 {
  font-size: .8rem;
  font-weight: 600;
  background-color: #F0F0F0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
}

.BusinessResult_price__3lHjt {
  margin-left: 10px;
}

.BusinessResult_commentBox__13SNc {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.BusinessResult_chatIcon__2Ny68 {
  position: relative;
  top: 3px;
}

.BusinessResult_transactionBox__1k6cT {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: .8rem;
}

.BusinessResult_transaction__1SFqB {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.BusinessResult_svg__1FG-r {
  color: #2eb187;
}



#Home_home__6h5Bm {
  margin-top: 30px;
}

.Home_category__1c2gY {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  margin: 0px 20px;
  margin-top: 40px;
  width: 20vw;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #e3e3e3;
}
.Home_category__1c2gY:hover {
  background-color: transparent;
  box-shadow: 0 0 10px 0 #00000026;
}

.Home_heroBox__2vVXJ {
  position: relative;
  top: 0;
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(180deg, rgba(46, 46, 46, 0.97),rgba(46, 46, 46, .1) 40%), url('https://source.unsplash.com/1600x900/?store');
  background-size: cover;
  margin-bottom: 50px;
  z-index: -5;
}
