.searchBar {
  width: 100%;
  max-width: 968px;
  height: 48px;
  margin-right: auto;
  margin-left: 33px;
  border-radius: 5px;
  box-shadow: 0 2px 18px #00000026;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}

.inputL, .inputR {
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  border-radius: 0;
  padding: 0px 13px;
}

.inputL {
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
}

.inputR {
  border: none;
}

.searchBtn {
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.searchIcon {
  height: 100%;
  background-color: #E00707;
  padding: 0px;
  margin: 0px;
  border: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.borderPillContainer {
  background-color: white;
  border: none;
}
.borderPill {
  background-color: #ebebeb;
  margin-top: auto;
  margin-bottom: auto;
  width: 2px;
  height: 60%;
}