.form {
  margin-left: 20rem;
  margin-top: 2rem;
}

.textareaBox {
  position: relative;
}

.textbox {
  width: 50em;
  height: 25em;
  padding-top: 5rem;
  background: white;
}

.submitButton {
  width: 10em;
}

.footer {
  margin-bottom: 20rem;
}